.photos .upload {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: inline-block;
  padding: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 600px) {
  .photos .upload {
    padding: 0 !important;
    box-shadow: none;
    border: none;
  }
}
