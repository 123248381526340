.tl {
  margin-top: 50px;
  position: relative;
  text-align: center;
  display: inline-block;
  list-style: none;
  padding: 0;
  --padding: 15px;
  --itemHeight: 50px;
  --itemPadding: 20px;
  --dotSize: 8px;
  --timePadding: 8px;
}
.tl::before {
  top: calc(var(--itemHeight) / 2);
  width: 2px;
  background: #000;
  height: calc(100% - var(--itemHeight) - var(--padding));
  content: '';
  position: absolute;
}
.tl li {
  height: var(--itemHeight);
  line-height: var(--itemHeight);
  margin-bottom: var(--padding);
  position: relative;
}
.tl li.active {
  font-weight: bold;
}
.tl li.previous::before,
.tl li.active::before {
  background: rgb(176, 111, 250);
}
.tl li.previous::after,
.tl li.active::after {
  content: '';
  width: 4px;
  height: calc(100% + 15px);
  position: absolute;
  top: 0;
  background: rgb(104, 17, 204);
}
.tl li.active::after {
  background: linear-gradient(
    180deg,
    rgba(104, 17, 204, 1),
    rgba(118, 91, 148, 0)
  );
  height: calc(100%);
}
.tl li::before {
  content: '';
  width: var(--dotSize);
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: calc(50% - (var(--dotSize) / 2));
  z-index: 1;
}
.tl li small {
  position: absolute;
  display: inline-block;
  margin-top: 1px;
}
.tl li span::before {
  content: '';
  width: 12px;
  top: 19px;
  aspect-ratio: 1;
  transform: rotate(45deg);
  background: #dadada;
  position: absolute;
  z-index: -1;
}
.tl li span {
  border-radius: 5px;
  border: 1px solid #dadada;
  height: var(--itemHeight);
  line-height: calc(var(--itemHeight) - 2px);
  padding: 0 15px;
  display: block;
  background-color: #fff;
}
.tl li:nth-of-type(even) {
  transform: translateX(calc(-50% - var(--itemPadding)));
  text-align: right;
}
.tl li:nth-of-type(even)::before {
  transform: translateX(calc(var(--itemPadding) - 1px - (var(--dotSize) / 4)));
}
.tl li:nth-of-type(even) small {
  transform: translateX(100%);
  right: calc((var(--itemPadding) * -1) - var(--timePadding));
}
.tl li:nth-of-type(even) span::before {
  right: -7px;
}
.tl li:nth-of-type(odd) {
  transform: translateX(calc(50% + var(--itemPadding)));
  text-align: left;
}
.tl li:nth-of-type(odd)::before {
  transform: translateX(
    calc((var(--itemPadding) * -1) - 1px - (var(--dotSize) / 4))
  );
}
.tl li:nth-of-type(odd) small {
  transform: translateX(-100%);
  left: calc((var(--itemPadding) * -1) - var(--timePadding));
}
.tl li:nth-of-type(odd) span::before {
  left: -7px;
}
.tl li:nth-of-type(even)::after {
  right: -23px;
}
.tl li:nth-of-type(odd)::after {
  left: -21px;
}

@media only screen and (max-width: 600px) {
  .tl {
    --padding: 25px;
  }
  .tl::before {
    left: 0;
  }
  .tl li,
  .tl li * {
    transform: unset !important;
  }
  .tl li {
    margin-left: var(--itemPadding);
  }
  .tl li::before {
    left: calc((var(--itemPadding) * -1) + 1px - (var(--dotSize) / 2));
    transform: unset !important;
  }
  .tl li small {
    left: 0 !important;
    right: unset !important;
    top: -20px;
  }
  .tl li span {
    text-align: left !important;
    border: none;
    padding: 0;
  }
  .tl li:nth-of-type(even)::after {
    right: unset;
    left: -21px;
  }
  .tl li::after {
    height: calc(100% + 40px);
  }
}
.timeline > small {
  display: block;
  margin-bottom: 10px;
  font-size: 10px;
  opacity: 0.5;
}
