.rsvp {
  text-align: left;
}
.rsvp-container {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.rsvp-container > .row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
}

@media only screen and (max-width: 600px) {
  .rsvp {
    padding: 15px;
  }
}
