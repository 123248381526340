.trauzeuge {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  border-radius: 5px;
  margin-top: 50px;
}
.trauzeuge .image::before {
  content: '';
  --inset: 3px;
  --width: 2px;
  position: absolute;
  width: calc(100% - var(--inset) * 2);
  aspect-ratio: 1;
  border: var(--width) solid #fff;
  border-radius: 50%;
  left: var(--inset);
  top: var(--inset);
}
.trauzeuge .image {
  position: relative;
  width: 50%;
  aspect-ratio: 1;
  background-position: top center;
  background-size: cover;
  border-radius: 50%;
}
.trauzeuge .about {
  opacity: 0.5;
  font-size: 12px;
  margin: 15px 30px;
}
.trauzeuge h6 {
  margin-top: 15px;
}
.trauzeuge small {
}
.trauzeuge a {
}
.trauzeugenInformation {
  background: #fff;
  /* min-height: calc(100% + 50px); */
  margin-left: -30px;
  margin-right: -30px;
  z-index: 1;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trauzeugenInformation h2 {
  padding-top: 15px;
  color: rgb(62, 3, 60);
}
.trauzeugenInformation p {
  position: relative;
  padding-bottom: 15px;
  max-width: 70%;
  margin-top: 15px;
  font-size: 15px;
}
.trauzeugenInformation p::after {
  content: '';
  position: absolute;
  background: rgb(62, 3, 60);
  bottom: 0;
  --size: 20px;
  left: 50%;
  width: var(--size);
  margin-left: calc(var(--size) / -2);
  height: 2px;
}
.trauzeugenInformation p:last-of-type::after {
  display: none;
}
.trauzeuge .others > span {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.trauzeuge .others > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}
.trauzeuge .others > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.trauzeuge .others > div > div > img {
  width: 80px;
  border-radius: 50%;
}
.trauzeuge .others > div > div > span {
}
@media only screen and (max-width: 600px) {
  .trauzeuge .others > div {
    flex-direction: column;
  }
  .trauzeuge .image {
    width: 80%;
  }
  .trauzeuge .others > div > div > img {
    border-radius: 10px;
  }
  .trauzeuge {
    margin-top: 0;
  }
}
