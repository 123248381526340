@media only screen and (max-width: 991px) {
  .navbar-collapse > div {
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 15px;
  }
  .navbar-collapse a {
    text-align: right;
    color: #000 !important;
    height: 50px;
    line-height: 50px;
    padding: 0 10px 0 30px;
  }
}
.navbar-collapse a.active {
  font-weight: bold;
}
.navbar-brand:focus,
.navbar-brand:hover {
  color: #fff;
}
.navbar a {
  color: #fff;
  text-decoration: none;
}
.navbar .navbar-collapse a {
  padding: 0 10px;
}
.navbar {
  background: transparent !important;
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  --bs-navbar-toggler-border-color: #fff;
}
.navbar-toggler {
  color: #fff !important;
  border-color: #fff !important;
  fill: #fff !important;
}
.navbar-toggler > span {
  color: #fff !important;
  border-color: #fff !important;
  fill: #fff !important;
  --bs-navbar-toggler-icon-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
}
.navbar a {
  position: relative;
}
.navbar-collapse a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 5px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.2) 2px
  );
  transition: all 0.3s;
}
.navbar a:not(:hover)::before {
  opacity: 0;
  bottom: 5px;
}
