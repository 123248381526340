body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #ffd8d8, #ddffdc);
}
#root {
  min-height: calc(100vh - (20px * 2));
  width: calc(100vw - 100px);
  margin: 20px 50px;
  background: #111;
  overflow: hidden;
  box-shadow: 0 0 15px #0000006c;
  border-radius: 5px;
  background: #fff;
  max-width: 1000px;
  position: relative;
}

#page {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow: hidden;
}
.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
}
.flex {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 15px;
}

@media only screen and (max-width: 600px) {
  #root {
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
}
