#header {
  position: relative;
  width: 100%;
  height: 40vh;
}
#header .navbar {
  z-index: 1;
}
#background {
  height: 100%;
  width: 100%;
  position: relative;
  background: linear-gradient(0deg, #1f3c1f, #acafb1);
}
.background {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.background.dimmed {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
#background .header-image {
  width: 100%;
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}
#background .header-image:not(.show) {
  opacity: 0;
}
