@import url('https://fonts.googleapis.com/css2?family=Cousine&family=Parisienne&display=swap');

.home {
  padding: 50px 50px;
  min-height: calc(60vh - 50px);
}
.st {
  display: inline-block;
  position: relative;
  font-size: 1rem;
}
.st::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  background: #f00;
  left: 0;
  top: 50%;
  transform: rotate(-5deg);
}
.home > h1 {
  margin-top: -190px;
  z-index: 1;
  color: #fff;
  position: relative;
  margin-bottom: 00px;
}
.home > h1 > span {
  font-family: 'Parisienne', cursive;
  display: block;
  height: 0px;
  line-height: 0px;
  overflow: hidden;
  transition: all 0.3s;
  opacity: 0;
  font-size: 70px;
  text-shadow: 0 0 5px #000;
}
.home > h1 > span.active {
  height: 70px;
  line-height: 70px;
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  .home > h1 {
    margin-top: -190px;
    margin-bottom: 00px;
  }
  .home > h1 > span {
    font-size: 40px;
  }
  .home > h1 > span.active {
    height: 40px;
    line-height: 40px;
  }
}
.home .hashtag {
  font-weight: bold;
  font-size: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0.5;
  height: 10px;
  line-height: 10px;
}
.overlayDate {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: #fff; */
  background: linear-gradient(45deg, #ffd8d8, #ddffdc);
  transition: all 1s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: min(10vw, 20vh);
  position: fixed;
  z-index: 1;
}
.overlayDate h2 {
  font-size: min(10vw, 20vh);
}
.overlayDate.active {
  opacity: 0;
  height: 0px;
}
.naviMosaic {
  margin-top: 80px;
}
.naviMosaic .item {
  color: #fff;
  margin-bottom: 30px;
  text-decoration: none !important;
  aspect-ratio: 16/9;
  overflow: hidden;
}
.naviMosaic .item .item-background {
  background-position: center;
  height: 100%;
  background-size: cover;
}
@media only screen and (min-width: 600px) {
  .naviMosaic .item.col-md-12 {
    aspect-ratio: calc(16 / 9 * 3);
  }
}
.naviMosaic .item .item-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;
  text-shadow: 0 0 3px #000;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.naviMosaic .item:hover .item-name {
  background: rgba(0, 0, 0, 0.8);
}
.days-left {
  position: relative;
}
.days-left .heart {
  font-size: 200px;
  color: rgb(62, 3, 60);
}
.days-left span {
  position: absolute;
  color: #fff;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-70%);
  font-size: 50px;
  text-shadow: 0 0 5px #000;
}
.days-left span small {
  font-size: 20px;
  display: block;
  opacity: 0.5;
}
.fnt {
  font-family: 'Parisienne', cursive;
  font-weight: bold;
  font-size: 45px;
}
