.menu .flex > div {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px 30px;
  text-align: left;
  /* border: 1px solid #000; */
  border-radius: 5px;
  flex: 1;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
}
.menu .flex > div > ul {
  list-style: none;
  padding: 0;
}
.menu .flex > div > h2 {
  text-align: center;
}
/* .menu {
  padding: 0 50px 50px 50px;
  display: flex;
  flex-direction: column;
} */

@media only screen and (max-width: 600px) {
  .menu .flex {
    flex-direction: column;
  }
}
