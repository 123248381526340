#anfahrt {
  padding: 0;
}
#anfahrt .row {
  margin: 50px -15px;
}
#anfahrt img {
  width: 100%;
}

.anfahrtinfo {
  text-align: left;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
}
.anfahrtinfo span {
  display: block;
  margin-bottom: 15px;
}

.anfahrtinfo span.t1 {
  font-weight: bold;
  font-size: 1.5rem;
}
.anfahrtinfo span a {
  color: inherit;
}
.anfahrtinfo span.t2 {
  font-size: 1.1rem;
}
.anfahrtinfo span.t3 {
}
#anfahrt .background {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: block;
  background-position: center bottom;
  background-size: cover;
}

#anfahrt iframe {
  width: 100%;
  min-height: 300px;
}
@media only screen and (max-width: 600px) {
  #anfahrt .row {
    /* flex-direction: column; */
  }
}
